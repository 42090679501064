import React, { useContext } from "react"
import { PageContext, PageData } from "../PageLayout"
import { PageMargin } from "../GlobalStyles"
import styled from "styled-components/macro"
import "fontsource-open-sans/300.css"

const IntroParagraphStyles = styled(PageMargin)`
  margin-bottom: 75px;
  p {
    text-align: center;
    font-size: 23px;
    font-weight: 300;
    line-height: 1.65em;
    @media screen and (min-width: 950px) {
      font-size: 30px;
    }
  }
`

const IntroParagraph = () => {
  const pageData: PageData = useContext(PageContext)
  const { introParagraph } = pageData
  return (
    <IntroParagraphStyles>
      <p>{introParagraph}</p>
    </IntroParagraphStyles>
  )
}

export default IntroParagraph
