import React, { useContext } from "react"
import styled from "styled-components/macro"
import { PageMargin, Button } from "../GlobalStyles"
import { PageContext, PageData } from "../PageLayout"

const CtaButtonStyles = styled(PageMargin)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px 0 75px;
  margin-bottom: 0;
  @media screen and (min-width: 1100px) {
    justify-content: flex-end;
  }
  a {
    margin: 0 15px 25px;
    @media screen and (min-width: 1100px) {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`

const CtaButtons = () => {
  const pageData: PageData = useContext(PageContext)
  const { seniorMessagesUrl } = pageData
  return (
    <CtaButtonStyles>
      <Button as="a" href={seniorMessagesUrl} target="_blank" rel="nofollow">
        Senior Messages
      </Button>
      <Button as="a" href="#services">
        Services
      </Button>
      <Button as="a" href="/contact">
        Contact
      </Button>
    </CtaButtonStyles>
  )
}

export default CtaButtons
