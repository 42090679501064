import React, { useContext, useState, useRef, useEffect } from "react"
import { PageContext, PageData } from "../PageLayout"
import { Button, PageMargin } from "../GlobalStyles"
import color from "../scripts/colors"
import styled from "styled-components/macro"
import "fontsource-open-sans/300.css"
import { FaCaretDown } from "react-icons/fa"
import { Spring } from "react-spring/renderprops"

type FeatureToggleTypes = {
  listVis: boolean
}

const ServiceListStyle = styled(PageMargin)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 75px;
`

const ServiceStyle = styled.div`
  width: 400px;
  margin: 0 10px 20px;
  position: relative;
  h2,
  p,
  li,
  ul {
    display: block;
    font-weight: 300;
  }
  h2 {
    background-color: ${color("main", "lt")};
    color: white;
    padding: 20px 30px;
    font-size: 30px;
    line-height: 1em;
    margin: 0 0 5px;
  }
  ul {
    margin: 0 0 50px;
    padding: 0;
    height: 0;
    list-style-type: none;
    overflow: auto;
    position: absolute;
    top: 75px;
    background-color: white;
  }
  p,
  li {
    background-color: ${color("grey")};
    margin: 00;
    color: white;
    line-height: 1.7em;
    letter-spacing: 0.1em;
  }
  p {
    padding: 25px 30px;
  }
  li {
    position: relative;
    padding: 15px 20px 15px 30px;
    border-bottom: 5px solid white;
    p {
      padding: 0;
      margin: 0;
    }
    h3 {
      margin: 7px 0 10px;
      & + p {
        margin-bottom: 10px;
      }
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -5px;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
      border-left: 17px solid white;
    }
    &:last-child {
      margin: 0;
    }
  }
`

const DetailDesc = styled.p<{ compHeight: number }>`
  height: ${props => (props.compHeight ? `${props.compHeight}px` : "auto")};
`

const FeatureToggle = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
  svg {
    transition: all 0.25s;
    transform: ${(props: FeatureToggleTypes) =>
      props.listVis ? "rotate(-180deg)" : null};
  }
`

const Service = ({ service, compHeight, setCompHeight }) => {
  const [listVis, setListVis] = useState(false)
  const listRef = useRef(null)
  const compHeightRef = useRef(null)

  useEffect(() => {
    const renderedCompHeight = compHeightRef.current.getBoundingClientRect()
      .height
    renderedCompHeight > compHeight && setCompHeight(renderedCompHeight)
    compHeightRef.current.style.height = `${compHeight}px`
    listRef.current.style.maxHeight = `${compHeight + 50}px`
  }, [compHeight])

  return (
    <ServiceStyle>
      <h2>{service.name}</h2>
      <DetailDesc ref={compHeightRef} compHeight={compHeight}>
        {service.description}
      </DetailDesc>
      <Spring
        from={{ height: "0" }}
        to={{ height: listVis ? "auto" : 0 }}
        onStart={() => {
          listVis && (listRef.current.style.zIndex = "1000")
        }}
        onRest={() => {
          !listVis && (listRef.current.style.zIndex = "-1")
        }}
      >
        {props => (
          <ul style={props} ref={listRef}>
            {service.detailList.map(detail => (
              <li key={detail._key}>
                {detail.title && <h3>{detail.title}</h3>}
                <p>{detail.text}</p>
              </li>
            ))}
          </ul>
        )}
      </Spring>
      <FeatureToggle listVis={listVis} onClick={() => setListVis(!listVis)}>
        Details
        <FaCaretDown />
      </FeatureToggle>
    </ServiceStyle>
  )
}

const Services = () => {
  const pageData: PageData = useContext(PageContext)
  const { services } = pageData
  const [compHeight, setCompHeight] = useState(0)
  const serviceList = services.map(service => {
    return (
      <Service
        service={service}
        key={service._key}
        compHeight={compHeight}
        setCompHeight={setCompHeight}
      />
    )
  })
  return (
    <ServiceListStyle id="services" as="section">
      {serviceList}
    </ServiceListStyle>
  )
}

export default Services
