import React, { useContext } from "react"
import ReactPlayer from "react-player"
import { PageMargin } from "../GlobalStyles"
import { PageContext, PageData } from "../PageLayout"
import styled from "styled-components/macro"
import color from "../scripts/colors"

const VideoPageMargin = styled(PageMargin)`
  @media screen and (min-width: 1300px) {
    width: 1000px;
  }
`

const PlayerWrap = styled.div`
  margin-bottom: 115px;
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.75);
  background-color: ${color("grey")};
  .ReactPlayer {
    position: absolute;
    top: 0;
    left: 0;
    .react-player__play-icon {
      transition: all 0.25s;
      border-width: 25px 0px 25px 35px !important;
      border-color: transparent transparent transparent ${color("link")} !important;
      filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.75));
      &:hover {
        border-color: transparent transparent transparent
          ${color("link", "hlt")}!important;
      }
    }
  }
`

const Video = () => {
  const pageData: PageData = useContext(PageContext)
  const { video } = pageData
  return (
    <>
      {video && (
        <VideoPageMargin>
          <PlayerWrap>
            {video && (
              <ReactPlayer
                className="ReactPlayer"
                url={video}
                width="100%"
                height="100%"
                light
                playing
                config={{
                  youtube: {
                    playerVars: {
                      modestbranding: 1,
                      rel: 0,
                    },
                  },
                }}
              />
            )}
          </PlayerWrap>
        </VideoPageMargin>
      )}
    </>
  )
}

export default Video
