import React, { useContext } from "react"
import styled from "styled-components/macro"
import { PageContext, PageData } from "../PageLayout"
import color from "../scripts/colors"
import "fontsource-open-sans/300.css"
import getCldnyUrl from "../scripts/getCldnyUrl"

type BannerImage = {
  imgSrc: string
}

const BannerStyles = styled.div<{ imgSrc: string }>`
  width: 100%;
  height: 250px;
  background-image: ${(props: BannerImage) =>
    props.imgSrc
      ? `url(${getCldnyUrl(props.imgSrc, "h_300,c_scale,f_auto,q_auto")})`
      : null};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 75% center;
  position: relative;
  display: flex;
  align-items: flex-end;
  @media screen and (min-width: 950px) {
    background-position: -325px center;
    height: 350px;
    background-image: ${(props: BannerImage) =>
      props.imgSrc
        ? `url(${getCldnyUrl(props.imgSrc, "h_400,c_scale,f_auto,q_auto")})`
        : null};
  }
  @media screen and (min-width: 1100px) {
    height: 450px;
    background-image: ${(props: BannerImage) =>
      props.imgSrc
        ? `url(${getCldnyUrl(props.imgSrc, "h_500,c_scale,f_auto,q_auto")})`
        : null};
  }
  @media screen and (min-width: 1400px) {
    background-position: center;
  }
  .TaglineStyles {
    display: none;
    @media screen and (min-width: 1100px) {
      display: block;
    }
  }
`

const TaglineStyles = styled.div`
  background-color: ${color("grey", "dk", 0.85)};
  padding: 20px;
  color: white;
  margin-bottom: 0;
  padding-left: 20px;
  position: static;
  display: block;
  @media screen and (min-width: 1100px) {
    display: none;
    position: absolute;
    top: 364px;
    max-width: calc(1300px / 3);
  }
  @media screen and (min-width: 1400px) {
    padding-left: calc((100% - 1300px) / 2);
  }
  h2,
  h3 {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
    margin: 0;
    text-align: center;
    @media screen and (min-width: 1100px) {
      text-align: left;
    }
  }
  h2 {
    color: white;
    font-size: 30px;
    margin-bottom: 15px;
    @media screen and (min-width: 1100px) {
      margin-bottom: 40px;
    }
  }
  h3 {
    font-weight: 300;
  }
`

const Tagline = mobile => {
  console.log(mobile)
  const pageData: PageData = useContext(PageContext)
  const { tagline, subTagline } = pageData
  return (
    <TaglineStyles className="TaglineStyles">
      <h2>{tagline}</h2>
      <h3>{subTagline}</h3>
    </TaglineStyles>
  )
}

const Banner = () => {
  const pageData: PageData = useContext(PageContext)
  const { banner } = pageData
  return (
    <>
      <BannerStyles imgSrc={banner}>
        <Tagline />
      </BannerStyles>
      <Tagline />
    </>
  )
}

export default Banner
