import React from "react"
import PageLayout from "../PageLayout"
import Banner from "../components/Banner"
import CtaButtons from "../components/CtaButtons"
import IntroParagraph from "../components/IntroParagraph"
import Services from "../components/Services"
import Testimonials from "../components/Testimonials"
import Video from "../components/Video"

export default function Home() {
  return (
    <PageLayout>
      <Banner />
      <CtaButtons />
      <IntroParagraph />
      <Video />
      <Services />
      <Testimonials />
    </PageLayout>
  )
}
