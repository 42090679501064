import React, { useContext } from "react"
import styled from "styled-components/macro"
import { PageContext, PageData } from "../PageLayout"
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa"
import color from "../scripts/colors"
import { PageMargin, SectionTitle } from "../GlobalStyles"
import "fontsource-open-sans/700.css"
import "fontsource-open-sans/300.css"
import "fontsource-open-sans/300-italic.css"

const TestimonialSectionStyle = styled(PageMargin)`
  margin-bottom: 75px;
`

const TestimonialWrapStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const TestimonialStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 20px 50px;
  p {
    display: block;
    width: calc(100% - 50px);
    position: relative;
    overflow: hidden;
    padding: 20px 25px;
    @media screen and (min-width: 550px) {
      width: 400px;
    }
  }
  span {
    position: relative;
    z-index: 100;
  }
  svg {
    position: absolute;
    color: ${color("grey", "dk")};
    z-index: 50;
    font-size: 50px;
    &:first-child {
      top: -7px;
      left: -2px;
    }
    &:last-child {
      bottom: -7px;
      right: -2px;
    }
  }
`

const TestimonialText = styled.p`
  background-color: ${color("grey")};
  font-size: 17px;
  line-height: 1.7em;
  font-weight: 300;
  font-style: italic;
  letter-spacing: 0.1em;
  margin: 0 0 5px;
  color: white;
`

const TestimonialName = styled.p`
  background-color: ${color("main", "lt")};
  color: white;
  font-size: 15px;
  font-weight: 700;
  line-height: 1em;
  text-transform: uppercase;
  margin: 0;
`

const Testimonials = () => {
  const pageData: PageData = useContext(PageContext)
  const { testimonials } = pageData
  return (
    <>
      {testimonials.length > 0 && (
        <TestimonialSectionStyle id="testimonials" as="section">
          <SectionTitle>Testimonials</SectionTitle>
          <TestimonialWrapStyle>
            {testimonials.map(testimonial => {
              return (
                <TestimonialStyle key={testimonial._key}>
                  <TestimonialText>
                    <FaQuoteLeft />
                    <span>{testimonial.text}</span>
                    <FaQuoteRight />
                  </TestimonialText>
                  <TestimonialName>{testimonial.name}</TestimonialName>
                </TestimonialStyle>
              )
            })}
          </TestimonialWrapStyle>
        </TestimonialSectionStyle>
      )}
    </>
  )
}

export default Testimonials
